export const translations = {
  "Wagering requirements on withdrawals": {
    sv: "Omsättningskrav på uttag",
  },
  "Wagering requirements on deposit": {
    sv: "oms.krav på insättning",
  },
  "Withdrawal attempt": {
    sv: "Uttagsförsök",
  },
  Speed: {
    sv: "Hastighet",
  },
  "Find Starbust (Speed)": {
    sv: "Hitta Starburst (Hastighet)",
  },
  "Average chat response time": {
    sv: "Genomsnittlig svarstid chatt",
  },
  "Response time from live chat (avg)": {
    sv: "Responstid från live chat (avg)",
  },
  "Rank from other review sites": {
    sv: "Rank från andra jämförelsesidor",
  },
  One: {
    sv: "Ett",
  },
  Two: {
    sv: "Två",
  },
  Three: {
    sv: "Tre",
  },
  Sort: {
    sv: "Sortera",
  },
  Direct: {
    sv: "Direkt",
  },
  "Load more casinos": {
    sv: "Ladda fler casinon",
    no: "Vis flere casinoer",
  },
  "View less casinos": {
    sv: "Se färre casinon",
  },
  "Visit casino": {
    sv: "Till casinot",
    no: "Til casinoet",
  },
  Visit: {
    sv: "Besök",
  },
  "Proceed to": {
    sv: "Vidare till",
    no: "Videre til",
  },
  "Hot!": {
    sv: "Hett nu!",
  },
  "New!": {
    sv: "Nytt!",
  },
  "Popular!": {
    sv: "Populär!",
  },
  "Crispy!": {
    sv: "Krispigt!",
  },
  "Remember that...": {
    sv: "Tänk på att...",
  },
  "Did you know?": {
    sv: "Visste du att?",
  },
  Source: {
    sv: "Källa",
  },
  "Read news": {
    sv: "Läs nyheten",
  },
  "Read more": {
    sv: "Läs mer",
  },
  Articles: {
    sv: "Artiklar",
  },
  "Found no posts": {
    sv: "Fant inga artiklar",
  },
  "Last updated": {
    sv: "Senast uppdaterad",
  },
  "Table of contents": {
    sv: "Innehåll",
  },
  "Content editor": {
    sv: "Innehållsansvarig",
  },
  About: {
    sv: "Om",
  },
  "Contact us": {
    sv: "Kontakt oss",
  },
  Contact: {
    sv: "Kontakt",
  },
  "Page does not exist": {
    sv: "Sidan finns ej",
  },
  "Oops.. something went wrong": {
    sv: "Oops.. nu blev något fel",
  },
  Search: {
    sv: "Sök",
  },
  "Thank you for visiting": {
    sv: "Tack för att du besökte",
  },
  "Click here": {
    sv: "Klicka här",
  },
  "if you aren't forwarded to": {
    sv: "om du inte skickas vidare till",
  },
  "Casino has english license": {
    se: "Casinot har svensk licens",
    sv: "Casinot har svensk licens",
    ca: "Casino has canadian license",
  },
  "Casino open for english players": {
    se: "Casinot öppen för svenska spelare",
    sv: "Casinot öppen för svenska spelare",
    ca: "Casino open for canadian players",
    in: "Casino open for indian players",
  },
  "Our rating": {
    sv: "Vårt betyg",
  },
  "English license": {
    se: "Svensk licens",
    sv: "Svensk licens",
    ca: "Canadian license",
  },
  "Games offered": {
    sv: "Spelutbud",
  },
  "Number of games": {
    sv: "Antal spel",
  },
  Support: {
    sv: "Support",
  },
  "Tax free winnings": {
    sv: "Skattefria vinster",
  },
  License: {
    sv: "Licens",
  },
  "Bonus license": {
    sv: "Bonuslicens",
  },
  "Game providers": {
    sv: "Spelleverantörer",
  },
  "Mobile casino": {
    sv: "Mobilcasino",
  },
  "Live casino": {
    sv: "Livecasino",
  },
  "Fast registration": {
    sv: "Snabbregistrering",
  },
  "E-legitimation": {
    sv: "E-legitimation",
  },
  "Deposit options": {
    sv: "Insättninger via",
  },
  "Withdrawal methods": {
    sv: "Uttagsmetoder",
  },
  "Deposit methods": {
    sv: "Insättningsmetoder",
  },
  "Minimum deposit": {
    sv: "Minsta insättning",
  },
  "FS no deposit": {
    sv: "FS utan insättning",
  },
  Email: {
    sv: "E-mail",
  },
  "Send email": {
    sv: "Maila",
  },
  Phone: {
    sv: "Telefon",
  },
  "Live chat": {
    sv: "Livechatt",
  },
  Telephone: {
    sv: "Telefon",
  },
  Website: {
    sv: "Hemsida",
  },
  To: {
    sv: "Till",
    no: "Til",
    en: "To",
  },
  "New customers. 18+. Gamble responsibly.": {
    no: "Kun tilgjengelig for nye kunder. 18+. Spill ansvarlig.",
  },
  "Affiliate disclosure": {
    sv: "Annonsinformation",
    no: "Annonseinformasjon",
  },
  "No Account": {
    sv: "Inget konto",
  },
  "Without account": {
    sv: "Utan konto",
  },
  "No turnover": {
    sv: "Utan omsättningskrav",
  },
  Turnover: {
    sv: "Omsättningskrav",
  },
  "Wagering req.": {
    sv: "Oms.krav",
  },
  "Deposit wagering": {
    sv: "Omsättkningskav på insättning",
  },
  "Wagering games": {
    sv: "Oms.krav spel",
  },
  "No bonus": {
    sv: "Inga bonus",
  },
  Without: {
    sv: "Utan",
  },
  No: {
    sv: "Inga",
    no: "Ingen",
  },
  No2: {
    en: "No",
    sv: "Nej",
  },
  No3: {
    en: "No",
    sv: "Ingen",
  },
  Yes: {
    sv: "Ja",
  },
  Founded: {
    sv: "Lanseringsår",
  },
  "Casino bonus": {
    sv: "Casinobonus",
  },
  "Sports bonus": {
    sv: "Sportbonus",
  },
  "Bonus wagering requirements": {
    sv: "Omsättningskrav på bonus",
  },
  "Bonus not available": {
    sv: "Bonus ej tillgänglig",
  },
  "Spin wagering requirements": {
    sv: "Omsättningskrav på spin",
  },
  "Free spins not available": {
    sv: "Free spins ej tillgängligt",
  },
  "Min. odds": {
    sv: "Minsta odds",
  },
  Withdrawal: {
    sv: "Uttag",
  },
  "Withdrawal Time": {
    sv: "Uttagstid",
  },
  "Withdrawal Time estimated": {
    sv: "Uttagstid (Estimerad)",
  },
  "(tested)": {
    sv: "(vårt test)",
  },
  "Withdrawal Fee": {
    sv: "Uttagsavgift",
  },
  "Min Withdrawal": {
    sv: "Minsta uttag",
  },
  "Min deposit": {
    sv: "Minsta insättning",
  },
  "Bonus min deposit": {
    sv: "Bonus min insättning",
  },
  Deposit: {
    sv: "Insättning",
  },
  hour: {
    sv: "tim",
  },
  hours: {
    sv: "tim",
  },
  h: {
    sv: "h",
  },
  minutes: {
    sv: "minuter",
  },
  min: {
    sv: "min",
  },
  seconds: {
    sv: "sekunder",
  },
  sec: {
    sv: "sek",
  },
  News: {
    sv: "Nyheter",
  },
  Archive: {
    sv: "Arkiv",
  },
  January: {
    sv: "Januari",
  },
  February: {
    sv: "Februari",
  },
  March: {
    sv: "Mars",
  },
  April: {
    sv: "April",
  },
  May: {
    sv: "Maj",
  },
  June: {
    sv: "Juni",
  },
  July: {
    sv: "Juli",
  },
  August: {
    sv: "Augusti",
  },
  September: {
    sv: "September",
  },
  October: {
    sv: "Oktober",
  },
  November: {
    sv: "November",
  },
  December: {
    sv: "December",
  },
  Result: {
    sv: "Resultat",
  },
  "Facts about": {
    sv: "Fakta om",
  },
  "More info": {
    sv: "Mer info",
  },
  Facts: {
    sv: "Fakta",
  },
  "Bonus game": {
    sv: "Bonusspel",
  },
  Progressive: {
    sv: "Progressiv",
  },
  allgames: {
    sv: "allaspel",
  },
  "Up to": {
    sv: "Upp till",
  },
  Oddsbonus: {
    sv: "Oddsbonus",
  },
  "Next draw": {
    sv: "Nästa dragning",
  },
  "Sitemap for": {
    sv: "Webbplatskarta över",
  },
  "Livecasino & Table Games": {
    sv: "Livecasino/Bordsspel",
  },
  "Odds & Betting": {
    sv: "Odds/Betting",
  },
  Bingo: {
    sv: "Bingo",
  },
  Lotto: {
    sv: "Lotto",
  },
  Slots: {
    sv: "Slots",
  },
  Horses: {
    sv: "Trav",
  },
  Sports: {
    sv: "Sport",
  },
  Fantazy: {
    sv: "Fantazy",
  },
  Baccarat: {
    sv: "Baccarat",
  },
  Roulette: {
    sv: "Roulette",
  },
  Blackjack: {
    sv: "Blackjack",
  },
  Livecasino: {
    sv: "Livecasino",
  },
  Read: {
    sv: "Läs",
  },
  Review: {
    sv: "Recension",
  },
  "Signup to our newsletter": {
    sv: "Registrera dig för nyhetsbrev",
  },
  "Shares license with": {
    sv: "Delar licensen med",
  },
  Owner: {
    sv: "Ägare",
  },
};
